import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';

export default function C01(props) {
  return (
    <div className={`c01 ${css(styles.flex, styles.flex_layout)}`}>
      <h1 className={css(styles.description, styles.description_layout)}>
        {props.hero_title}
        <span className={css(styles.unit)}>
          {props.descriptionUnit}
        </span>
      </h1>
      <div className={css(styles.flex_row)}>
        <div className={css(styles.intro, styles.intro_layout)}>
          {props.text_body}
        </div>
      </div>
    </div>
  );
}

C01.inStorybook = true;

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: 0
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    font: '400 45px/1.2 "Noto Sans TC", Helvetica, Arial, serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '2.6666667461395264px',
    whiteSpace: 'nowrap',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '35px',
      textAlign: 'center'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '30px',
      textAlign: 'center'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '38px',
      textAlign: 'center'
    }
  },
  description_layout: {
    position: 'relative',
    margin: 0,
    display: 'flex',
    alignItems: 'baseline'
  },
  unit: {
    fontSize: 30,
    alignSelf: 'center'
  },
  flex_row: {
    display: 'flex'
  },
  intro: {
    display: 'flex',
    justifyContent: 'center',
    font: '14px/1.5714285714285714 "Noto Sans TC", Helvetica, Arial, serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  intro_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '0px auto'
  }
});

C01.propTypes = {
  hero_title: PropTypes.string,
  text_body: PropTypes.string,
  descriptionUnit: PropTypes.string
};

C01.defaultProps = {
  hero_title: null,
  text_body: null,
  descriptionUnit: null
};
