/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';
import PC01 from './PC01';
import arrowImg from '../../../../../assets/goShoppingArrow.png';
import InfoRow from './InfoRow';

function renderBackground(props) {
  return (
    <Px.div
      data-background={props.background}
      x="0px 428fr 0px"
      y="29fr minmax(0px, max-content) 1fr"
      className={`${css(backgroundStyles.background)} swiper-lazy`}
      xsX="0px 428fr 0px"
      xsY="29fr minmax(100px,max-content) 1fr"
    >
      <Px.div
        x="27fr 401fr 0fr"
        y="80fr minmax(0px, 600fr) 5fr"
        className={css(backgroundStyles.group)}
      >
        <Px.div
          // FIXME: grid template fixed.
          x="1fr minmax(0, max-content) 141fr"
          y="246fr minmax(0px, max-content) 10fr"
          absolute
          className={css(backgroundStyles.group1)}
        >
          <div>
            <div
              className={css(
                backgroundStyles.subtitle1,
                backgroundStyles.subtitle1_layout
              )}
            >
              {props.subtitle1}
            </div>
            <div
              className={css(
                backgroundStyles.subtitle2,
                backgroundStyles.subtitle2_layout
              )}
            >
              {props.subtitle2}
            </div>
          </div>

        </Px.div>

        <Px.div
          style={{ '--src': `url(${props.casetitle})` }}
          x="1fr 452fr 1fr"
          y="0fr minmax(220px,200fr) 60fr"
          className={css(backgroundStyles.casetitle)}
        />
      </Px.div>
    </Px.div>
  );
}

export default function P001(props) {
  return (
    <div className={`p001 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 428fr 0px"
        y="86fr minmax(0px, max-content) 86fr"
        className={css(styles.flex)}
      >
        <div className={css(styles.flex_row)}>{renderBackground(props)}</div>
        <InfoRow info={props} />
      </Px.div>
    </div>
  );
}

P001.inStorybook = true;

const backgroundStyles = StyleSheet.create({
  background: {
    display: 'flex',
    background: 'center center / cover no-repeat',
    width: '100%',
    height: '100%'
  },
  group: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  group1: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  subtitle1: {
    font: '300 14px/2.142857142857143 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px'
  },
  subtitle1_layout: {
    position: 'relative',
    backgroundColor: 'rgb(248,248,246)',
    width: '100%',
    margin: '0 18.5px 0px 25.5px',
    '@media (max-width: 575.98px)': {
      margin: '0px 20px'
    }
  },
  group2: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(248,248,246)',
    width: '100%',
    height: '100%'
  },
  subtitle2: {
    font: '300 14px/2.142857142857143 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    transform: 'translateX(32px)'
  },
  subtitle2_layout: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'rgb(248,248,246)',
    margin: '0 73.5px 0 51.5px',
    '@media (max-width: 767.98px)': {
      margin: '0 20px'
    },
    '@media (max-width: 575.98px)': {
      margin: '0 20px'
    }
  },
  casetitle: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  }
});

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'visible',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex_row: {
    display: 'flex',
    flex: '1 1 auto'
  },
  flex1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  flex2: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex2_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '0px 0px 0px 28px',
    '@media (max-width: 575.98px)': {
      margin: 0
    }
  },
  flex2_col: {
    display: 'flex',
    flex: '1 1 154px'
  },
  component: {
    position: 'relative'
  },
  component_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  flex2_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 20
  },
  flex3: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex3_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '20px 0px 0px 24.5px',
    '@media (max-width: 575.98px)': {
      margin: '20px 0px 0px'
    }
  },
  flex3_col: {
    display: 'flex',
    flex: '1 1 154px'
  },
  flex3_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 20
  },
  flex_row1: {
    display: 'flex'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(41,41,41)'
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: '65px auto 0px',
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '20px auto'
    }
  },
  flex4: {
    display: 'flex'
  },
  flex4_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: '12px 26.5px'
  },
  flex4_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 9
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    font: '18px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px'
  },
  text_layout: {
    position: 'relative',
    flex: '0 1 185px',
    height: 'min-content',
    width: 185,
    minWidth: 185,
    margin: 0
  },
  flex4_spacer1: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 0.5
  },
  flex4_col: {
    display: 'flex',
    flex: '0 1 52.5px'
  },
  img: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  }
});

P001.propTypes = {
  description1: PropTypes.string,
  description2: PropTypes.string,
  description3: PropTypes.string,
  description4: PropTypes.string,
  intro1: PropTypes.string,
  intro2: PropTypes.string,
  intro3: PropTypes.string,
  intro4: PropTypes.string,
  // shoppingbutton: PropTypes.object,
  description1Unit: PropTypes.string,
  description2Unit: PropTypes.string,
  description3Unit: PropTypes.string,
  description4Unit: PropTypes.string
};

P001.defaultProps = {
  description1: null,
  description2: null,
  description3: null,
  description4: null,
  intro1: null,
  intro2: null,
  intro3: null,
  intro4: null,
  // shoppingbutton: null,
  description1Unit: null,
  description2Unit: null,
  description3Unit: null,
  description4Unit: null
};
