import React, { useEffect } from 'react';
import { init, cleanup } from './fb';

export default function FbChatPlugin() {

  useEffect(() => {
    init();

    return () => {
      cleanup();
    };
  }, []);


  return (
    <div>
      <div id="fb-root" />

      <div id="fb-customer-chat" className="fb-customerchat" />
    </div>
  );
}

