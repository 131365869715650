import axios from 'axios';

export async function request(options) {
  try {
    console.log(`[API] request: ${JSON.stringify(options, null, 2)}`);
    const result = await axios(options);
    console.log(`[API] status: ${result?.status}`);

    return result;
  } catch (err) {
    const status = err?.response?.status;
    // const data = err?.response?.data;
    const msg = err?.message ?? err;

    const log = [
      status ? `status: ${status}` : null,
      // data ? `data: ${JSON.stringify(data)}` : null,
      msg ? `msg: ${msg}` : null
    ]
      .filter((el) => !!el)
      .join(', ');

    console.log(`[API] error ${log}`);

    throw err;
  }
}

// FIXME: test for db
export const getHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      // return `${r[0]}:5000`;
      // return 'http://192.168.0.215:5000';
    }
  }

  // dev
  if (/^dev-/.test(window.location.host)) {
    return 'https://dev-admin.maauu.com';
  }

  // pro
  return 'https://admin.maauu.com';
};
