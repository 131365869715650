import numeral from 'numeral';

export default class Plan {
  constructor(params) {
    const {
      id,
      subdomain,
      name,
      title,
      isInLine,
      isActive,
      depositType,
      deposit,
      spaceCount,
      builderName,
      hasLine,
      hasMessenger
    } = params;

    this.id = id;
    this.subdomain = subdomain;
    this.name = name;
    this.title = title;
    this.isActive = isActive;
    this.isInLine = isInLine;
    this.depositType = depositType;
    this.deposit = deposit ? numeral(deposit).format() : '0';
    this.spaceCount = spaceCount;
    this.builderName = builderName ?? 'NULL';
    this.hasLine = hasLine;
    this.hasMessenger = hasMessenger;
  }

  static fromRes(data) {
    return new Plan(data);
  }
}
