import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import styles from './styles.module.css';

const PayErrorPage = (props) => {
  const history = useHistory();

  return (
    <div className={clsx('page', styles.page)}>
      <div
        className={clsx(styles.img_box)}
        onClick={() => history.replace('/center')}
      />
    </div>
  );
};

export default PayErrorPage;
